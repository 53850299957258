import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const originalPush = VueRouter.prototype.push
      //修改原型对象中的push方法
  VueRouter.prototype.push = function push(location) {
          return originalPush.call(this, location).catch(err => err)
   }

const routes = [
  { //大首页
    path: '/index',
    component: () => import('../views/index/index.vue'),
  },{ //列表页
    path: '/cate/:cid',
    component: () => import('../views/index/index2.vue'),
  },
  { //详情
    path: '/detail/:id', component: () => import('../views/detail/index.vue')
  },
  { //支付
    path: '/pay',  component: () => import('../views/pay/index.vue')
  },
  { //支付状态
    path: '/payResult',  component: () => import('../views/pay/result.vue')
  },
  //重定向：
  { path: '*', redirect: '/index' },
  // cad主题
  {
    path: '/theme/cad',  
    meta: {
      title: 'Autocad官网下载'
    },
    component: () => import('../views/cad/index.vue')
  },
  { //cad详情
    path: '/cad_detail/:id',
    meta: {
      title: 'Autocad官网下载'
    }, 
    component: () => import('../views/cad/cad_detail.vue')
  },
  // davinci主题
  {
    path: '/theme/davinci',  
    meta: {
      title: 'DaVinci Resolve官网下载'
    },
    component: () => import('../views/davinci/index.vue')
  },
  { //davinci详情
    path: '/davinci_detail/:id',
    meta: {
      title: 'DaVinci Resolve官网下载'
    }, 
    component: () => import('../views/davinci/davinci_detail.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior: () => {
    return {x:0, y:0};
  }
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
})


export default router
