
<template>
  <div>
    <!-- 一级路由出口 -->
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
export default {

}

  var _hmt = _hmt || [];
  (function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8c742060486448e9c1ef648fe460e27f";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

</script>

<style>

</style>